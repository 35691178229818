jQuery(document).ready(function () {
    // Handle dropdown changes
    jQuery('#country-select, #zone-select, #state-select, #city-select').on('change', function () {
        const id = jQuery(this).attr('id');

        if (id == "zone-select") {
            const stateSelect = jQuery('#state-select');
            stateSelect.empty().append('<option>Select State</option>'); // Default option
            const citySelect = jQuery('#city-select');
            citySelect.empty().append('<option>Select City</option>'); // Default option
            const zone = jQuery(this).val();
            if (zone) {
                fetchStatesByZone(zone);
            }
        }

        if (id == "state-select") {
            const citySelect = jQuery('#city-select');
            citySelect.empty().append('<option>Select City</option>'); // Default option
            const state = jQuery(this).val();
            if (state) {
                fetchCitiesByState(state);
            }
        }

        if (id == "country-select") {
            const zoneSelect = jQuery('#zone-select');
            zoneSelect.val('');
            const stateSelect = jQuery('#state-select');
            stateSelect.empty().append('<option>Select State</option>'); // Default option
            const citySelect = jQuery('#city-select');
            citySelect.empty().append('<option>Select City</option>'); // Default option
            if (jQuery(this).val() !== "india") {
                zoneSelect.attr("disabled", true);
                stateSelect.attr("disabled", true);
                citySelect.attr("disabled", true);
            } else {
                zoneSelect.removeAttr("disabled");
                stateSelect.removeAttr("disabled");
                citySelect.removeAttr("disabled");
            }
        }

        fetchDistributors(1); // Load distributors
    });

    // Fetch states by zone
    function fetchStatesByZone(zone) {
        const country = jQuery('#country-select').val();
        jQuery.ajax({
            type: 'POST',
            url: ajax_obj.ajax_url,
            data: {
                action: 'load_states_cities_by_zone',
                zone: zone,
                country: country
            },
            success: function (response) {
                if (response.success) {
                    // Populate states
                    const states = response.data.states;
                    const stateSelect = jQuery('#state-select');
                    stateSelect.empty().append('<option>Select State</option>'); // Default option
                    states.forEach(state => {
                        stateSelect.append('<option value="' + state + '">' + state + '</option>');
                    });

                    // Clear cities dropdown
                    const citySelect = jQuery('#city-select');
                    citySelect.empty().append('<option>Select City</option>'); // Default option
                }
            }
        });
    }

    // Fetch cities by state
    function fetchCitiesByState(state) {
        const country = jQuery('#country-select').val(); // Optional
        const zone = jQuery('#zone-select').val(); // Optional

        jQuery.ajax({
            type: 'POST',
            url: ajax_obj.ajax_url,
            data: {
                action: 'load_cities_by_state',
                state: state,
                country: country,
                zone: zone
            },
            success: function (response) {
                if (response.success) {
                    // Populate cities
                    const cities = response.data.cities;
                    const citySelect = jQuery('#city-select');
                    citySelect.empty().append('<option>Select City</option>'); // Default option
                    cities.forEach(city => {
                        citySelect.append('<option value="' + city + '">' + city + '</option>');
                    });
                }
            }
        });
    }

    // Fetch distributors logic (unchanged)
    function fetchDistributors(page) {
        const selectedCountry = jQuery('#country-select').val();
        const selectedZone = jQuery('#zone-select').val();
        const selectedState = jQuery('#state-select').val();
        const selectedCity = jQuery('#city-select').val();

        const data = {
            action: 'filter_distributors_by_country',
            country: selectedCountry,
            page: page
        };

        if (selectedCountry === 'india' && selectedZone && selectedZone !== 'Select Zone') {
            data.zone = selectedZone;
        }

        if (selectedState && selectedState !== 'Select State') {
            data.state = selectedState;
        }

        if (selectedCity && selectedCity !== 'Select City') {
            data.city = selectedCity;
        }

        jQuery.ajax({
            type: 'POST',
            url: ajax_obj.ajax_url,
            data: data,
            success: function (response) {
                jQuery("#all-results").hide();
                jQuery('#filtered-results').html(response);
            },
            error: function (xhr, status, error) {
                console.error('Error: ' + xhr.status);
            }
        });
    }
});
